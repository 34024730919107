import React, { useRef, useState } from 'react'

import Layout from '../layout/Default'
import {
  Hero, HeroContainer, HeroTitle, HeroBodyContainer, HeroBody, HeroButton, HeroIllustrationContainer,
  Introduction, ImgIntro, IntroductionBodyContainer, IntroductionTitle, IntroductionBody, Truncate, ReadMore,
  Service, ServiceTitle, ServicesContainer,
  TestimoniesContainer, TestimoniTitle, Testimoni
} from '../styles/index.styled'

import Navigation from '../components/Navigation'
import ContactUs from '../components/ContactUs'
import CardService from '../components/Service'
import Testimony from '../components/Testimony'
import Footer from '../components/Footer'
import { HeroIllustration, WhatsApp } from '../components/Svg'

import IntroductionIllustrution from '../assets/img/introduction-illustration.png'
import RectCouchImg from '../static/our-service/furniture/cuci-sofa.png'

import { ServiceList, TestimoniList } from '../config'
import Meta from '../utils/Meta'

const scrollToRef = (ref: React.MutableRefObject<HTMLDivElement>) => window.scrollTo({
  left: 0,
  top: ref.current.offsetTop - 48,
  behavior: 'smooth'
})

const Index = (): React.ReactElement => {
  const [readMore, setReadMore] = useState(false)
  const serviceRef = useRef(null)
  const testiRef = useRef(null)
  const contactRef = useRef(null)
  const serviceScroll = () => scrollToRef(serviceRef)
  const testiScroll = () => scrollToRef(testiRef)
  const contactScroll = () => scrollToRef(contactRef)

  return (
    <Layout>
      <Meta />
      <Navigation
        onClickService={serviceScroll}
        onClickTestimoni={testiScroll}
        onClickContact={contactScroll}
      />
      <Hero>
        <HeroContainer>
          <HeroBodyContainer>
            <HeroTitle>Bersih Tanpa Kompromi</HeroTitle>
            <HeroBody>
              Buat furnitur yang anda miliki bersih, tanpa terkecuali!
            </HeroBody>
            <HeroButton onClick={() => window.open('https://api.whatsapp.com/send?phone=6287882036426')}>
              <WhatsApp size={16} />
              <span style={{ marginLeft: 8 }}>Hubungi Kami</span>
            </HeroButton>
          </HeroBodyContainer>
          <HeroIllustrationContainer>
            <HeroIllustration />
          </HeroIllustrationContainer>
        </HeroContainer>
      </Hero>
      <Introduction>
        <HeroContainer>
          <ImgIntro src={IntroductionIllustrution} />
          <IntroductionBodyContainer>
            <IntroductionTitle>Apa itu <br /> Bearsih?</IntroductionTitle>
            <IntroductionBody>
              <Truncate lineCount={readMore ? 100 : 5}>
                Bearsih merupakan perusahaan yang melayani pencucian berbagai furnitur rumah, baik itusofa, meja, kursi, karpet, bahkan springbed.Di Bearsih, kami berkomitmen untuk menyajikan pelayanan yang terbaik untuk anda, karena moto kami adalah “Kami tidak pernah meragukan pelanggan meski permintaannya aneh aneh”.
              </Truncate>
              <ReadMore onClick={() => setReadMore(!readMore)}>
                {readMore ? 'Sembunyikan' : 'Lihat Selengkapnya'}
              </ReadMore>
            </IntroductionBody>
          </IntroductionBodyContainer>
        </HeroContainer>
      </Introduction>
      <Service id="our-service" ref={serviceRef}>
        <ServiceTitle>Layanan Kami</ServiceTitle>
        <ServicesContainer>
          {ServiceList.map((val, key) => (
            <CardService name={val.name} desc={val.desc} img={require(`../static/${val.image}`) || RectCouchImg} key={key} />
          ))}
        </ServicesContainer>
      </Service>
      <Testimoni id="testimoni" ref={testiRef}>
        <TestimoniTitle>Testimoni</TestimoniTitle>
        <TestimoniesContainer>
          {TestimoniList.map((val, key) => (
            <Testimony name={val.name} quote={val.quote} role={val.role} img={require(`../static/${val.image}`) || RectCouchImg} key={key} />
          ))}
        </TestimoniesContainer>
      </Testimoni>
      <div id="contact" ref={contactRef}>
        <ContactUs />
      </div>
      <Footer />
    </Layout>
  )
}

export default Index
