import React from 'react'
import slugify from 'slugify'
import { Truncate } from '../../styles/index.styled'
import { ServiceCard, ServiceCardBody, ServiceCardImg, ServiceCardTitle, ServiceCardDesc, ServiceCardFooter } from './index.styled'

interface Props {
  name: string;
  desc: string;
  img: string;
}

export default function cardService ({ name, desc, img }: Props): React.ReactElement {
  return (
    <ServiceCard>
      <ServiceCardImg src={img} />
      <ServiceCardBody>
        <ServiceCardTitle>
          {name}
        </ServiceCardTitle>
        <ServiceCardDesc>
          <Truncate lineCount={2}>
            {desc}
          </Truncate>
        </ServiceCardDesc>
        <ServiceCardFooter as="a" href={`/layanan/${slugify(name)}`}>
          Lihat Detail
        </ServiceCardFooter>
      </ServiceCardBody>
    </ServiceCard>
  )
}
