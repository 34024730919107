import React from 'react'
import {
  IntroductionBody, Truncate
} from '../../styles/index.styled'

import {
  Base,
  By,
  ByImage,
  ByName,
  ByRole,
  ByInformation
} from './index.styled'

interface Props {
  quote: string;
  name: string;
  role: string;
  img: string;
}

export default function cardTestimoni ({ quote, name, role, img }: Props): React.ReactElement {
  return (
    <Base>
      <IntroductionBody>
        <Truncate>
          {`"${quote}"`}
        </Truncate>
      </IntroductionBody>
      <By>
        <ByImage src={img} />
        <ByInformation>
          <ByRole>{role}</ByRole>
          <ByName>{name}</ByName>
        </ByInformation>
      </By>
    </Base>
  )
}
