import styled from 'styled-components'

const Base = styled.div`
  background-color: #3C405B;
  box-sizing: border-box;
  border-radius: 4px;
  border-bottom-right-radius: 40px;
  color: white;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.4px;
  line-height: 24px;
  max-width: initial;
  margin: 8px 0;
  padding: 16px;
  position: relative;
  width: 100%;
  word-wrap: break-word;

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}


  @media (min-width: 1200px) {
    margin: 0px;
    max-width: 360px;
  }
`

const By = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 32px 0 8px;
`

const ByInformation = styled.div`
  margin: 0 16px;
`

const ByImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  object-fit: cover;
`

const ByRole = styled.span`
  background-color: rgba(242, 204, 142, .4);
  border-radius: 28px;
  color: #F2CC8E;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: 400;
`

const ByName = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 8px;
`

export default {}
export { Base, By, ByImage, ByName, ByRole, ByInformation }
