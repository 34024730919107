import styled from 'styled-components'
import { IntroductionBody } from '../../styles/index.styled'

const ServiceCard = styled.div`
  background-color: #3C405B;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin: 8px 0px;
  max-width: initial;
  width: 100%;

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: 992px) {
    max-width: 320px;
    margin: 0 8px;

    &:first-child {
      margin-left: 0px;
    }
  
    &:last-child {
      margin-right: 0px;
    }
  }


  @media (min-width: 1200px) {
    margin: 0px;
  }
`

const ServiceCardBody = styled.div`
  background-color: #3C405B;
  color: white;
  padding: 16px;
`

const ServiceCardTitle = styled.div`
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
`

const ServiceCardDesc = styled(IntroductionBody)`
  font-size: 16px;
  font-weight: 100;
  margin: 16px 0;
  letter-spacing: 0.4px;
  line-height: 24px;
`

const ServiceCardFooter = styled(ServiceCardDesc)`
  color: #F2CC8E;
  float: right;
  font-weight: 300;
  text-decoration: none;
`

const ServiceCardImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 280px
`

export default {}
export { ServiceCard, ServiceCardBody, ServiceCardImg, ServiceCardTitle, ServiceCardDesc, ServiceCardFooter }
