const ServiceList = [
  {
    name: 'Cuci Sofa',
    desc: 'Paket layanan pencucian sofa terbaik yang bisa anda dapatkan.',
    detailDesc: `Menangani Kebersihan Furniture Anda\n\n
    Pelayanan Bearsih menawarkan service yang bergaransi disertai dengan keterjaminan kualitas. Kenyamanan dan kebersihan furniture anda merupakan prioritas kami, tim kami berisikan orang-orang yang profesional dan sudah memiliki pengalaman pada bidangnya, kami siap memenuhi kebutuhan anda di rumah maupun di kantor, jangan ragu lagi, segera hubungi kami jika anda berminat menggunakan jasa kami.\n\n
    Kualitas furniture anda pasti tetap terjaga.\n\n
    Kami menggunakan peralatan yang terjaga kualitas dan kebersihannya sehingga furniture anda tak hanya bersih namun juga wangi, kami betul-betul memperhatikan detil demi kenyamanan anda, kami menawarkan pelayanan terbaik dengan harga terjangkau, serta setiap pembersihan selalu menggunakan anti bakteri supaya furniture anda terbebas dari bakteri dan bau.`,
    hasPriceList: true,
    image: 'our-service/furniture/cuci-sofa.png',
    detailImage: 'our-service/furniture/sofa-detail.png',
    carouselImage: [
      'our-service/furniture/1.jpg',
      'our-service/furniture/2.jpg',
      'our-service/furniture/3.jpg',
      'our-service/furniture/4.jpg',
      'our-service/furniture/5.jpg',
      'our-service/furniture/6.jpg',
      'our-service/furniture/7.jpg',
      'our-service/furniture/8.jpg'
    ]
  },
  {
    name: 'Servis AC',
    desc: 'Pelayanan Service AC untuk AC anda yang kurang dingin bisa menjadi seperti baru lagi.',
    detailDesc: `AC anda kurang dingin? Panggil Kami!\n\n
    Jika anda mengalami kendala pada air conditioner anda, kami siap membantu. kami siap mengganti freon anda yang mulai menipis dengan yang baru sehingga AC anda tetap terjaga kualitas serta kedinginannya untuk menjaga anda dari kepanasan.\n\n
    Selain mengganti freon, tentu saja kami juga akan membersihkan AC anda dari debu-debu sehingga kesehatan anda tetap terjaga.`,
    hasPriceList: false,
    image: 'our-service/ac-service/cuci-ac.png',
    detailImage: 'our-service/ac-service/ac-detail.png',
    carouselImage: [
      'our-service/ac-service/1.jpg',
      'our-service/ac-service/2.jpg',
      'our-service/ac-service/3.jpg',
      'our-service/ac-service/4.jpg'
    ]
  },
  {
    name: 'Cuci Toren',
    desc: 'Pelayanan Cuci Toren yang sudah mulai kotor dijamin akan bersih kembali. ',
    detailDesc: `Selain membersihkan furniture dan service Air Conditioner, kami juga mampu membersihkan toren anda dari lumut-lumut supaya air yang keluar dari keran tetap terjaga kebersihannya, sehingga kesehatan keluarga anda tetap terjaga dan jauh dari penyakit yang timbul akibat kotoran-kotoran.\n\n
    Tunggu apalagi? Segera hubungi kami!`,
    hasPriceList: false,
    image: 'our-service/torrent/cuci-torrent.jpg',
    detailImage: 'our-service/torrent/torrent-detail.png',
    carouselImage: [
      'our-service/torrent/1.jpg',
      'our-service/torrent/2.jpg',
      'our-service/torrent/3.jpg'
    ]
  }
]

const TestimoniList = [
  {
    name: 'Hafizh Rifqi',
    image: 'testimoni/hafizh.png',
    quote: 'Bearsih membantu saya dalam pemeliharan furnitur-furnitur yang ada di headquarter saya... Saya sangat puas',
    role: 'Pegawai Swasta'
  },
  {
    name: 'Ario Eko',
    image: 'testimoni/eko.png',
    quote: 'Saya sudah berlangganan menggunakan jasa Bearsih sejak dulu dan pelayanan mereka tidak pernah mengecewakan!',
    role: 'Mahasiswa'
  },
  {
    name: 'Qadavi',
    image: 'testimoni/davi.png',
    quote: 'Recommended! Semua furnitur di istana negara selalu saya percayakan kepada Bearsih dan tidak pernah mengecewakan!',
    role: 'Pengusaha'
  }
]
exports.ServiceList = ServiceList
exports.TestimoniList = TestimoniList
